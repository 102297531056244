<template>
  <el-dialog :visible="dialogVisible" :show-close="false" @open="open">
    <div slot="title" class="left_top">
      <div class="left_icon">
        <span></span>
        <span>{{title}}</span>
      </div>
    </div>
    <el-form
      label-position="top"
      width="900px"
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      class="ruleForm"
      label-width="80px"
    >
      <el-form-item label="企业名称" prop="workEnterpriseCode">
        <el-select
          v-model="ruleForm.workEnterpriseCode"
          filterable
          :placeholder="'请选择企业名称'"
          style="width: 100%"
          @change="inputChange"
          :disabled="show"
        >
          <el-option
            v-for="item in enterpriseSelectList"
            :key="item.code"
            :label="item.name "
            :value="item.code"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="企业区域" prop="workEnterpriseAreaCode">
        <el-select
          v-model="ruleForm.workEnterpriseAreaCode"
          filterable
          style="width: 100%;"
          :placeholder="'请输入企业区域'"
          :disabled="show"
        >
          <el-option-group
            v-for="group in cityList"
            :key="group.provinceName"
            :label="group.provinceName"
          >
            <span style="color:red" slot="label">{{group.provinceName}}</span>
            <el-option
              v-for="item in group.citys"
              :key="item.code"
              :label="item.cityName"
              :value="item.code"
            ></el-option>
          </el-option-group>
        </el-select>
      </el-form-item>
      <el-form-item label="门店编号" prop="no">
        <el-input v-model="ruleForm.no" :placeholder="'请输入门店编号'"></el-input>
      </el-form-item>
      <el-form-item label="门店名称" prop="name">
        <el-input v-model="ruleForm.name" :placeholder="'请输入门店名称'"></el-input>
      </el-form-item>
      <el-form-item label="门店地址" prop="address">
        <el-input v-model="ruleForm.address" :placeholder="'请输入门店地址'"></el-input>
      </el-form-item>
      <el-form-item label="联系方式" prop="mobile">
        <el-input v-model="ruleForm.mobile" :placeholder="'请输入联系方式'"></el-input>
      </el-form-item>
      <el-form-item label="门店备注" prop="remark">
        <el-input v-model="ruleForm.remark" type="textarea" :placeholder="'请输入门店备注'"></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
      <el-button @click="closeDialog('ruleForm')">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { api } from '/src/api/base';
// 企业列表
export const workEnterpriseList = api()('work_enterprise.list.json');
export const workEnterpriseStoreAdd = api()('work_enterprise_store.add.json');
export const workEnterpriseStoreInfo = api()('work_enterprise_store.info.json');
export const workEnterpriseStoreEdit = api()('work_enterprise_store.edit.json');
export const workEnterpriseAreaContainArea = api()('work_enterprise_area.containArea.json');

export default {
  components: {

  },
  data () {
    return {
      ruleForm: {

        workEnterpriseCode: '',
        workEnterpriseAreaCode: '',
        no: '',
        name: '',
        address: '',
        mobile: '',
        remark: ''
      },
      rules: {
        workEnterpriseCode: [{ required: true, message: '请选择企业名称', trigger: 'blur' }],
        workEnterpriseAreaCode: [{ required: true, message: '请选择区域', trigger: 'blur' }],
        name: [{ required: true, message: '请输入门店名称', trigger: 'blur' }],
        address: [{ required: true, message: '请输入门店地址', trigger: 'blur' }],
      },
      newCode: this.code,
      enterpriseSelectList: [],
      show: false,
      provinceList: [],
      cityList: [],
    };
  },
  props: {
    dialogVisible: Boolean,
    title: String,
    code: String
  },
  created () {


  },
  methods: {
    proviceChange (val) {
      console.log(val);

    },
    open () {

      workEnterpriseList().then((res) => {
        this.enterpriseSelectList = res.list
      });
      if (this.title === '编辑门店信息') {
        this.storeInfo()
        this.show = true
        console.log(this.show);
      }
      else {
        this.show = false
      }
    },
    storeInfo () {
      workEnterpriseStoreInfo({ code: this.code }).then((res) => {


        this.ruleForm.workEnterpriseCode = res.workEnterpriseCode
        this.inputChange(res.workEnterpriseCode)
        this.ruleForm.workEnterpriseAreaCode = res.workEnterpriseAreaCode
        console.log(this.ruleForm.workEnterpriseAreaCode);
        this.ruleForm.no = res.no
        this.ruleForm.name = res.name
        this.ruleForm.address = res.address
        this.ruleForm.mobile = res.mobile
        this.ruleForm.remark = res.remark
      });
    },
    inputChange (val) {
      console.log(val);
      const params = {
        workEnterpriseCode: this.ruleForm.workEnterpriseCode
      };
      workEnterpriseAreaContainArea(params).then((res) => {
        this.cityList = res
        console.log(this.cityList)
      });

    },
    submitForm (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.title === '编辑门店信息') {
            const params = {
              code: this.code,
              workEnterpriseCode: this.ruleForm.workEnterpriseCode,
              workEnterpriseAreaCode: this.ruleForm.workEnterpriseAreaCode.toString(),
              no: this.ruleForm.no,
              name: this.ruleForm.name,
              address: this.ruleForm.address,
              mobile: this.ruleForm.mobile,
              remark: this.ruleForm.remark
            };
            workEnterpriseStoreEdit(params).then(res => {
              console.log(res);
              this.closeDialog(formName)
            });
          }
          else {
            const params = {
              workEnterpriseCode: this.ruleForm.workEnterpriseCode,
              workEnterpriseAreaCode: this.ruleForm.workEnterpriseAreaCode.toString(),
              no: this.ruleForm.no,
              name: this.ruleForm.name,
              address: this.ruleForm.address,
              mobile: this.ruleForm.mobile,
              remark: this.ruleForm.remark
            };
            workEnterpriseStoreAdd(params).then(res => {
              console.log(res);
              this.closeDialog(formName)
            });
          }



        } else {

          return false;
        }
      });
    },
    resetForm (formName) {
      this.$refs[formName].resetFields();
    },
    closeDialog (formName) {
      this.resetForm(formName)

      this.$emit('update:dialogVisible', false);
      this.$emit('func', this.dialogVisible);
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  padding-bottom: 15px;
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ruleForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.el-form-item {
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
  width: 49%;
}

.el-form-item__label {
  padding-bottom: 0 !important;
}
</style>
